export const calculatePrecision = (value: number) => {
  if (value < 1) {
    return 2
  }

  if (value < 10) {
    return 1
  }

  return 0
}

export const getMaxValueByKey = <T = unknown>(key: keyof T, xs: T[]) => {
  return Math.max(
    ...xs.map((x) => (!isNaN(Number(x[key])) ? Number(x[key]) : 0))
  )
}

export const toPrecise = (val: number, precision = 2) =>
  Number(Number(val).toFixed(precision))

// addresses floating-point arithmetic issues and ensures rounding operations are accurate
export const to2DecimalPoints = (val: number) => {
  return Math.round((val + Number.EPSILON) * 100) / 100
}

export const isMultipleOf = (multiple: number, n: number) => n % multiple === 0

export const isNumeric = (n: number | string) => {
  const num = parseFloat(n as string)
  return !isNaN(num) && isFinite(num)
}

export const nanoToMilliseconds = (nanoseconds: number | string) =>
  Number(nanoseconds) / 1000000

export const millisecondsToSeconds = (value: number) => {
  const seconds = value > 0 ? value / 1000 : 0
  return seconds.toFixed(2)
}

type Range = [start: number, end: number]

export const inRange = (value: number, start: number, end: number) => {
  return value >= start && value <= end
}

export const hasOverlap = ([start1, end1]: Range, [start2, end2]: Range) => {
  return (
    inRange(start1, start2, end2) ||
    inRange(end1, start2, end2) ||
    inRange(start2, start1, end1) ||
    inRange(end2, start1, end1)
  )
}

export const toRate = (num1?: number, num2?: number) => {
  if (num1 === undefined || num2 === undefined) {
    return undefined
  }

  if (num2 === 0) {
    return 0
  }

  return toPrecise((num1 / num2) * 100, 0)
}

export const toPercentage = (num: number) => {
  return toPrecise(num * 100, 0)
}
