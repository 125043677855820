import React, { ReactNode } from 'react'
import { Icon } from '@grafana/ui'
import styled, { css } from 'styled-components'

const common = css`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: normal;

  &:hover {
    text-decoration: underline;
  }

  > div {
    align-self: flex-start;
  }
`
const Link = styled.a`
  color: ${({ theme }) => theme.colors.text.link};
  ${common}
`

const DisabledPseudoLink = styled.span`
  color: ${({ theme }) => theme.colors.text.disabled};
  ${common}
`

export const ExternalLink = ({
  showIcon,
  url,
  onClick,
  children,
  disabled,
  ...props
}: {
  showIcon?: boolean
  url?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  children: ReactNode
  disabled?: boolean
}) => {
  if (disabled) {
    return (
      <DisabledPseudoLink {...props}>
        {showIcon && <Icon aria-hidden name="external-link-alt" />}
        {children}
      </DisabledPseudoLink>
    )
  }

  return (
    <Link
      href={url}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {showIcon && <Icon aria-hidden name="external-link-alt" />}
      {children}
    </Link>
  )
}
